<script setup lang="ts">
import {
  LeaseManagerApvc_Models_PaymentCategory,
  LeaseManagerApvc_Models_PaymentStatus,
  type LeaseManagerApvc_Controllers_RequestsResponses_AddSingleLeasePaymentRequest,
} from '~/data-services/gen/apvc'

interface Props {
  divisionId: string
}
const props = withDefaults(defineProps<Props>(), {})

// EMITS
const emit = defineEmits<{
  (
    event: 'confirm',
    value: {
      leaseId: string
      data: LeaseManagerApvc_Controllers_RequestsResponses_AddSingleLeasePaymentRequest
    },
  ): void
  (event: 'close'): void
}>()

const { data: leases, isFetching: fetchingLeasesOptions } =
  useAPIFetchAllLeases(props.divisionId)

const leaseId = ref('')
const paymentBody =
  ref<LeaseManagerApvc_Controllers_RequestsResponses_AddSingleLeasePaymentRequest>(
    {},
  )

// STATE
const allowImmidiateClosing = computed(
  () =>
    !(
      leaseId.value ||
      paymentBody.value.amount ||
      paymentBody.value.category ||
      paymentBody.value.status
    ),
)
const leasesOptions = computed(
  () =>
    leases.value?.map((lease) => ({
      label: lease.name as string,
      value: lease.id as string,
    })) || [],
)
const statusOptions = computed(() =>
  Object.keys(LeaseManagerApvc_Models_PaymentStatus).map((key) => ({
    label: key,
    value: (
      LeaseManagerApvc_Models_PaymentStatus as Record<
        string,
        LeaseManagerApvc_Models_PaymentStatus
      >
    )[key],
  })),
)
const categoryOptions = computed(() =>
  Object.keys(LeaseManagerApvc_Models_PaymentCategory).map((key) => ({
    label: key,
    value: (
      LeaseManagerApvc_Models_PaymentCategory as Record<
        string,
        LeaseManagerApvc_Models_PaymentCategory
      >
    )[key],
  })),
)

// CLOSE
const confirm = useConfirm()

const onConfirm = () => {
  emit('confirm', {
    data: { ...paymentBody.value, paidAt: new Date().toUTCString() },
    leaseId: leaseId.value,
  })
}

// Prevent closing while uploading
const onClose = () => {
  confirm.require({
    title: 'Are you sure you want to close?',
    body: 'You will lose any progress made on uploading documents to this lease.',
    confirm: () => {
      emit('close')
    },
    bypass: () => allowImmidiateClosing.value,
  })
}
</script>

<template>
  <ModalBase
    title="Add accounts payable"
    modal-class="max-w-[650px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
    :close-on-click="false"
    @confirm="onConfirm"
    @close="onClose"
  >
    <div class="relative flex flex-col gap-y-5">
      <FormKit
        v-model="leaseId"
        type="listbox"
        :disabled="fetchingLeasesOptions"
        :options="leasesOptions"
        outer-class="w-full"
        inner-class="!bg-gray-850"
      >
        <template #label>
          <span>Lease <span class="text-red">*</span></span>
        </template>
      </FormKit>
      <FormKit
        v-model="paymentBody.status"
        label="Status"
        type="listbox"
        :options="statusOptions"
        outer-class="w-full"
        inner-class="!bg-gray-850"
      />
      <FormKit
        v-model="paymentBody.category"
        label="Category"
        type="listbox"
        :options="categoryOptions"
        outer-class="w-full"
        inner-class="!bg-gray-850"
      />
      <FormKit
        v-model="paymentBody.amount"
        type="number"
        label="Amount"
        inner-class="!bg-gray-850"
      />
    </div>
  </ModalBase>
</template>
