import { useQuery } from '@tanstack/vue-query'
import type { LeaseManagerApvc_Dtos_LeaseDto } from '~/data-services/gen/apvc'

export default (divisionId: string) =>
  useQuery({
    queryKey: ['divisionId', divisionId, 'leases'],
    queryFn: () =>
      fetchAPIAPVC<LeaseManagerApvc_Dtos_LeaseDto[]>(
        `/leases?divisionId=${divisionId}`,
      ),
  })
